@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground tracking-normal;

  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

html {
  scroll-behavior: smooth;
}

.animate-fade-in-up {
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
}

.animation-delay-200 {
  animation-delay: 0.2s;
}

.animation-delay-400 {
  animation-delay: 0.4s;
}

.animation-delay-600 {
  animation-delay: 0.6s;
}

.animation-delay-800 {
  animation-delay: 0.8s;
}

.animation-delay-1000 {
  animation-delay: 1s;
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0) rotate(-12deg);
  }

  50% {
    transform: translateY(-10px) rotate(-12deg);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply tracking-tighter text-blue-700;

}

p {
  @apply leading-loose
}

/* Headings */
.page h1 {
  @apply text-2xl md:text-4xl lg:text-5xl font-bold mb-6 text-blue-700;
}

.page h2 {
  @apply text-xl md:text-3xl lg:text-4xl font-semibold my-4;
}

.page h3 {
  @apply text-lg md:text-2xl lg:text-3xl font-semibold mb-3;
}

/* Prevent underline and customize color for hyperlinks inside headings */
.page h1 a,
.page h2 a,
.page h3 a {
  @apply no-underline text-blue-600;
  position: relative;
  transition: color 0.2s;
}

.page h1 a::before,
.page h2 a::before,
.page h3 a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: currentColor;
  /* Uses the link's color */
  transition: width 0.3s ease;
}

.page h1 a:hover::before,
.page h2 a:hover::before,
.page h3 a:hover::before {
  width: 100%;
  /* Expands underline from left to right */
}

/* Paragraphs */
.page p {
  @apply text-base md:text-lg lg:text-base leading-relaxed lg:leading-loose mb-6;
}

/* Blockquotes */
.page blockquote {
  @apply border-l-4 border-gray-400 pl-4 italic mb-6 text-gray-600;
}

/* Lists */
.page ul {
  @apply list-disc list-inside mb-6 pl-4;
}

.page ol {
  @apply list-decimal list-inside mb-6 pl-4;
}

.page li {
  @apply mb-2;
}

/* Code Blocks */
.page pre {
  @apply bg-gray-800 text-white p-4 rounded-lg overflow-auto mb-6;
}

.page code {
  @apply px-2 py-1 rounded;
}

/* Images */
.page img {
  @apply w-full h-auto rounded-lg mb-6 shadow-sm;
}

/* Tables */
.page table {
  @apply w-full table-auto mb-6 border-collapse;
}

.page th {
  @apply border px-4 py-2 bg-gray-200;
}

.page td {
  @apply border px-4 py-2;
}

/* Links */
.page a {
  @apply text-blue-600 no-underline relative;
  transition: color 0.2s ease;
}

.page a::before {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: currentColor;
  transition: width 0.3s ease;
}

.page a:hover::before {
  width: 100%;
  /* Creates the sliding underline effect */
}

/* Break */
.page br {
  @apply my-2;
}

/* Custom background block */
.has-background {
  @apply bg-gray-100 sm:p-8 border rounded my-6;
}

/* Separator */
.wp-block-separator {
  @apply my-6;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.page {
  @apply leading-loose;
}
/* Headings */
.blog h1 {
  @apply text-3xl sm:text-6xl font-bold mb-6 leading-loose ;
}

.blog h2 {
  @apply text-2xl sm:text-4xl font-semibold my-4 leading-loose ;
}

.blog h3 {
  @apply text-xl sm:text-3xl font-semibold my-3 leading-loose ;
}
.blog h4 {
  @apply text-lg sm:text-2xl font-semibold my-3 leading-loose  ;
}

.blog p{
  @apply leading-loose mb-6 text-xl;
}
.blog li{
  @apply leading-loose text-xl;
}
.toc li a{
  @apply leading-loose text-lg mb-1;
}
.blog{ 
  @apply leading-loose  text-xl;
}
.related-posts h3{
  @apply text-lg sm:text-xl font-semibold mb-4;
}
.custom-picker .react-colorful {
  @apply sm:w-[400px] w-[300px] h-[400px];
}
.custom-picker .react-colorful__saturation {
  @apply rounded-t-md;
}
.custom-picker .react-colorful__hue,
.custom-picker .react-colorful__alpha {
  @apply h-[30px];
}
.custom-picker .react-colorful__hue {
  @apply rounded-b-md;
}
.custom-picker .react-colorful__alpha {
  @apply rounded-md mt-2.5;
}
.custom-picker .react-colorful__saturation-pointer,
.custom-picker .react-colorful__hue-pointer,
.custom-picker .react-colorful__alpha-pointer {
  @apply w-5 h-5;
}
.custom-picker .react-colorful__hue-pointer,
.custom-picker .react-colorful__alpha-pointer {
  @apply rounded-full;
}

.stars-small {
  background-image: radial-gradient(1px 1px at 25% 25%, white 100%, transparent),
    radial-gradient(1px 1px at 50% 50%, white 100%, transparent),
    radial-gradient(1px 1px at 75% 75%, white 100%, transparent),
    radial-gradient(1px 1px at 25% 75%, white 100%, transparent),
    radial-gradient(1px 1px at 75% 25%, white 100%, transparent),
    radial-gradient(1px 1px at 50% 25%, white 100%, transparent);
  background-size: 100px 100px;
  animation: twinkle 4s ease-in-out infinite;
  opacity: 0.5;
}

.stars-medium {
  background-image: radial-gradient(1.5px 1.5px at 25% 25%, white 100%, transparent),
    radial-gradient(1.5px 1.5px at 50% 50%, white 100%, transparent),
    radial-gradient(1.5px 1.5px at 75% 75%, white 100%, transparent);
  background-size: 150px 150px;
  animation: twinkle 6s ease-in-out infinite;
  opacity: 0.7;
}

.stars-large {
  background-image: radial-gradient(2px 2px at 25% 25%, white 100%, transparent),
    radial-gradient(2px 2px at 50% 50%, white 100%, transparent),
    radial-gradient(2px 2px at 75% 75%, white 100%, transparent);
  background-size: 200px 200px;
  animation: twinkle 8s ease-in-out infinite;
  opacity: 0.9;
}

.cloud1, .cloud2 {
  background: white;
  border-radius: 100px;
  height: 40px;
  position: absolute;
  width: 100px;
}

.cloud1:after, .cloud1:before,
.cloud2:after, .cloud2:before {
  background: white;
  border-radius: 50%;
  content: '';
  position: absolute;
}

.cloud1:after {
  height: 60px;
  left: 15px;
  top: -20px;
  width: 60px;
}

.cloud1:before {
  height: 40px;
  right: 15px;
  top: -10px;
  width: 40px;
}

.cloud2 {
  left: 100px;
  top: 100px;
  transform: scale(1.5);
}

@keyframes twinkle {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes float-delayed {
  0%, 100% { transform: translateY(-10px); }
  50% { transform: translateY(10px); }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

.animate-float-delayed {
  animation: float-delayed 8s ease-in-out infinite;
}

.bg-scanline {
  background: linear-gradient(
    0deg,
    rgba(0, 255, 0, 0.03) 50%,
    transparent 50%
  );
  background-size: 100% 4px;
}



@layer base {
  * {
    @apply border-border outline-ring/50;
  }
  body {
    @apply bg-background text-foreground;
  }
}